import React from 'react'
import styled from 'styled-components'

import { Layout } from './layout'
import { SEO } from './seo'
import { DynamicSection } from './dynamicSection'
import { Content } from '../types'
import { getBrowserTitle, getCurrentUrl, mapMetaTags } from '../util';

type LandingPageProps = {
  content: Content;
  pendingMessage?: string | JSX.Element
}

export const LandingPage: React.FC<LandingPageProps> = props => {
  const { content, pendingMessage = "Loading...", children } = props
  return <Layout>
    {content ? <>
      <SEO
        title={getBrowserTitle(content)}
        meta={mapMetaTags(content)}
        canonical={getCurrentUrl(content)}
      />
      <ContentContainer>
        {content.sections.map((section, index) => <DynamicSection section={section} key={index} />)}
        {children}
      </ContentContainer>
    </> : <ContentContainer>
      {pendingMessage}
    </ContentContainer>}
  </Layout>
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
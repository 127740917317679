import React from 'react'
import { graphql } from 'gatsby'

import { Content } from '../types'
import { LandingPage } from '../components/landingPage'

type LandingPageTemplateProps = {

  data: {
    Content: {
      getContents: {
        items: Content[]
      }
    }
  }
}

const LandingPageTemplate: React.FC<LandingPageTemplateProps> = props => {
  const content = props.data.Content.getContents.items[0];

  return <LandingPage content={content} />
}

export const query = graphql`
  query($slug: String!) {
    Content {
      getContents(limit: 1, filter: {type: {eq: "landingPage"}, slug: {eq: $slug}}) {
        items {
          id
          slug
          title
          type
          publishDate
          subtitle
          tags
          thumbnail
          attributes {
            name
            value
          }
          sections {
            bodies {
              data
              type
              attributes {
                name
                value
              }
            }
            type
            attributes {
              name
              value
            }
          }
        }
      }
    }
  }
`


export default LandingPageTemplate
